import React from "react"
import { Link } from "gatsby"

import Nav from "../components/nav";
import Featured from "../components/featured";
import Wrecks from "../components/wrecks";
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import FeedFB from '../components/Feed-FB';
import Footer from '../components/footer';
import './index.css';

const lang = {
  en: { ourDiveSites: 'Our dive sites' },
  sv: { ourDiveSites: 'Våra dykmål' },
};

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Nav />
    <Featured />
    {/* <Wrecks /> */}
    <Link to='/dive-sites' className='dive-sites'>{lang.en.ourDiveSites}</Link>
    <Footer />
  </Layout>
)

export default IndexPage
