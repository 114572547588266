import React from 'react';
import { graphql, navigate, StaticQuery } from 'gatsby';
import './featured.css';
import FeedFB from '../Feed-FB';

// TODO: make this featured.js to what should be displayed on the home page
export default () => (
  <StaticQuery
    query={graphql`
      query FeaturedQuery {
        allContentfulBlog(
          limit: 1
          sort: { fields: [createdAt], order: DESC }
          filter: {
            node_locale: {eq: "en-US",}
            featured: {eq:true}
          }
        ) {
          edges {
            node {
              id
              slug
              title
              shortDescription
              content {
                childMarkdownRemark {
                  html
                }
              }
              featuredImage {
                fluid(maxWidth: 1200, quality: 85) {
                  src
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    `}
    // TODO: change from divesite contentful. should be news or something static (home)
    render={data => (
      <header>
        {data.allContentfulBlog.edges.map(edge => (
          <div key={edge.node.id} className="header__section">
            <div className="header__hero" style={{ backgroundImage: `url(${edge.node.featuredImage.fluid.src})` }}></div>
            <div className="header__content">
              <div className="header__info">
                <h1 className="header__title">{edge.node.title}</h1>
              <div className="header__subtitle" dangerouslySetInnerHTML={
                  { __html: `${edge.node.content.childMarkdownRemark.html}` }
                } />
              </div>
              <div className="header__facebook-feed">
                <FeedFB />
              </div>
            </div>
          </div>
        ))}
      </header>
    )}
  />
)